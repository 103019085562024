/* eslint-disable */

export const randomNumber = Math.floor(Math.random() * 100000);

export function verifyCookie(cookieCode) {
  if (
    typeof OptanonActiveGroups !== "undefined" &&
    typeof process.env.GATSBY_GDPR_UUID !== "undefined"
  ) {
    const activeGroups = OptanonActiveGroups.split(",");
    return activeGroups.includes(String(cookieCode));
  } else {
    return true;
  }
}
