import React from "react";
import { Footer, RichtextEditor } from "@cncxt/imodium-component-library";
import Container from "../Container";
import { StaticQuery, graphql } from "gatsby";
import { footerProps } from "../../utils/interfaces/layoutInterfaces";
import CookieButton from "../CookieButton";
import styles from "./styles.module.scss";

interface Props {
  activeness?: boolean;
  siteFooterData?: any;
}
const SiteFooter = (Props: Props): JSX.Element => {
  const { activeness, siteFooterData } = Props;
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          imodiumFooterNavigation {
            data {
              ...FooterNavFragment
            }
          }
          currentBuildDate {
            currentDate
          }
        }
      `}
      render={({ imodiumFooterNavigation, currentBuildDate }: footerProps) => {
        const footerNavs = imodiumFooterNavigation.data;
        const buildDate = currentBuildDate.currentDate;
        const footerDataString = siteFooterData
          ? siteFooterData.replace("LAST_UPDATED_DATE", buildDate)
          : "";
        const footerData = siteFooterData ? JSON.parse(footerDataString) : [];
        const footerContent = footerData.content;
        const listItems = footerNavs.map(data => ({
          label: data.title,
          link: data.link.href,
        }));
        return (
          <Footer
            activeness={activeness}
            footerMenuList={listItems}
            footerMenuColor="white"
          >
            {process.env.GATSBY_GDPR_UUID && <CookieButton />}
            <Container>
              <div className={styles["footerContent"]}>
                <RichtextEditor jsonRichtextEditor={footerContent} />
              </div>
            </Container>
          </Footer>
        );
      }}
    />
  );
};
export default SiteFooter;
