import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@cncxt/imodium-component-library";
import axios from "axios";
import style from "./search.module.scss";
import { navigate } from "gatsby";

type TextFieldType = "text" | "number";
interface Props {
  id?: string;
  type?: TextFieldType;
  name: string;
  placeholder?: string;
  value?: string | number;
  onChange?: ({
    target: { value },
  }: {
    target: { value: string };
  }) => { key: "string"; count: "string" }[];
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  multiline?: boolean;
  error?: boolean;
  onClick?: () => void;
  hasFocus?: boolean;
  disabled?: boolean;
  classn?: string;
  footerData: string;
}

export default function Search({
  type = "text",
  onChange,
  error = false,
  startAdornment,
  endAdornment,
  multiline = false,
  onClick,
  disabled = false,
  classn,
  footerData,
  placeholder,
  ...others
}: Props) {
  const [dropDown, setDropDown] = useState([]);
  const [dropDownVisible, setDropDownVisible] = useState(true);
  const textRef = useRef<HTMLDivElement>();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [textRef]);

  async function navigatetoSearchPage(data: string) {
    setDropDownVisible(false);
    const domainURL = process.env.GATSBY_CMS_API_URL;
    const endpoint = "/search/";
    const response = await axios.get(
      `${domainURL}${endpoint}?keywords=${data}&limit=100`,
    );
    const tempData = response.data.data;
    //complete data
    const searchResults = [
      ...tempData.filter,
      ...tempData.pages,
      ...tempData.products,
    ];
    navigate(`/search/site/${data}`, {
      state: {
        footerData,
        searchResults,
      },
    });
  }

  function handleClickOutside(event) {
    if (textRef.current && textRef.current.contains(event.target)) {
      setDropDownVisible(true);
    } else {
      setDropDownVisible(false);
    }
  }

  return (
    <div ref={textRef}>
      <TextField
        navigatetoSearchPage={navigatetoSearchPage}
        setDropDown={setDropDown}
        type={type}
        onChange={onChange}
        error={error}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        onClick={onClick}
        disabled={disabled}
        classn={classn}
        others={others}
        placeholder={placeholder}
      />
      {dropDown && dropDownVisible && (
        <div>
          {" "}
          {dropDown.map(
            (suggestedItem: { key: "string"; count: "string" }[]) => {
              return (
                <div
                  className={style.dropdownContainer}
                  onClick={() => {
                    setDropDownVisible(false);
                    navigatetoSearchPage(suggestedItem["key"]);
                  }}
                  key={suggestedItem["key"] + suggestedItem["count"]}
                >
                  <span className={style.dropdownElement}>
                    {suggestedItem["key"]}
                  </span>
                  <span className={style.dropdownElement}>
                    {suggestedItem["count"]}
                  </span>
                </div>
              );
            },
          )}
        </div>
      )}
    </div>
  );
}
