// Necessary cookie constant
export const GDPR_NECESSARY_COOKIES = 1;

// Performance cookie constant
export const GDPR_PERFORMANCE_COOKIES = 2;

// Media cookie constant
export const GDPR_MEDIA_COOKIES = 8;

// GDPR cookie constant
export const GDPR_TARGETING_COOKIES = 4;

let GATSBY_SITE_MULTILINGUAL_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_SITE_MULTILINGUAL == null) {
  GATSBY_SITE_MULTILINGUAL_INIT = "false";
} else {
  // eslint-disable-next-line no-undef
  GATSBY_SITE_MULTILINGUAL_INIT = process.env.GATSBY_SITE_MULTILINGUAL;
}
export const GATSBY_SITE_MULTILINGUAL = GATSBY_SITE_MULTILINGUAL_INIT;

let GATSBY_SITE_TRANSLATION_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_SITE_LANGUAGE == null) {
  GATSBY_SITE_TRANSLATION_INIT = "default";
} else {
  // eslint-disable-next-line no-undef
  GATSBY_SITE_TRANSLATION_INIT = process.env.GATSBY_SITE_LANGUAGE;
}
export const GATSBY_SITE_TRANSLATION = GATSBY_SITE_TRANSLATION_INIT;

let GATSBY_SITE_LANGUAGE_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_SITE_LANGUAGE == null) {
  GATSBY_SITE_LANGUAGE_INIT = "en-au";
} else {
  // eslint-disable-next-line no-undef
  GATSBY_SITE_LANGUAGE_INIT = process.env.GATSBY_SITE_LANGUAGE;
}
export const GATSBY_SITE_LANGUAGE = GATSBY_SITE_LANGUAGE_INIT;

let GATSBY_SITE_DIRECTION_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_SITE_DIRECTION == null) {
  GATSBY_SITE_DIRECTION_INIT = "ltr";
} else {
  // eslint-disable-next-line no-undef
  GATSBY_SITE_DIRECTION_INIT = process.env.GATSBY_SITE_DIRECTION;
}
export const GATSBY_SITE_DIRECTION = GATSBY_SITE_DIRECTION_INIT;

let GATSBY_SHOW_WHERE_TO_BUY_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_SHOW_WHERE_TO_BUY == null) {
  GATSBY_SHOW_WHERE_TO_BUY_INIT = "false";
} else {
  // eslint-disable-next-line no-undef
  GATSBY_SHOW_WHERE_TO_BUY_INIT = process.env.GATSBY_SHOW_WHERE_TO_BUY;
}
export const GATSBY_SHOW_WHERE_TO_BUY = GATSBY_SHOW_WHERE_TO_BUY_INIT;

//using multilingual to show language switch button
let GATSBY_LANG_SWITCH_LINK_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_LANG_SWITCH_LINK == null) {
  GATSBY_LANG_SWITCH_LINK_INIT = "https://www.imodium-me.com/en";
} else {
  // eslint-disable-next-line no-undef
  GATSBY_LANG_SWITCH_LINK_INIT = process.env.GATSBY_LANG_SWITCH_LINK;
}
export const GATSBY_LANG_SWITCH_LINK = GATSBY_LANG_SWITCH_LINK_INIT;

let GATSBY_SHOW_WHERE_TO_BUY_LINK_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_SHOW_WHERE_TO_BUY_LINK == null) {
  GATSBY_SHOW_WHERE_TO_BUY_LINK_INIT = "https://www.imodium-me.com/en";
} else {
  // eslint-disable-next-line no-undef
  GATSBY_SHOW_WHERE_TO_BUY_LINK_INIT =
    // eslint-disable-next-line no-undef
    process.env.GATSBY_SHOW_WHERE_TO_BUY_LINK;
}
export const GATSBY_SHOW_WHERE_TO_BUY_LINK = GATSBY_SHOW_WHERE_TO_BUY_LINK_INIT;

let GATSBY_SHOW_DESKTOP_MENU_HOME_ICON_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_SHOW_DESKTOP_MENU_HOME_ICON == null) {
  GATSBY_SHOW_DESKTOP_MENU_HOME_ICON_INIT = "true";
  // eslint-disable-next-line no-undef
} else {
  GATSBY_SHOW_DESKTOP_MENU_HOME_ICON_INIT =
    // eslint-disable-next-line no-undef
    process.env.GATSBY_SHOW_DESKTOP_MENU_HOME_ICON;
}
export const GATSBY_SHOW_DESKTOP_MENU_HOME_ICON = GATSBY_SHOW_DESKTOP_MENU_HOME_ICON_INIT;

let GATSBY_SHOW_MOBILE_MENU_HOME_ICON_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_SHOW_MOBILE_MENU_HOME_ICON == null) {
  GATSBY_SHOW_MOBILE_MENU_HOME_ICON_INIT = "false";
  // eslint-disable-next-line no-undef
} else {
  GATSBY_SHOW_MOBILE_MENU_HOME_ICON_INIT =
    // eslint-disable-next-line no-undef
    process.env.GATSBY_SHOW_MOBILE_MENU_HOME_ICON;
}
export const GATSBY_SHOW_MOBILE_MENU_HOME_ICON = GATSBY_SHOW_MOBILE_MENU_HOME_ICON_INIT;

let GATSBY_SHOW_CAROUSEL_PLAY_AND_PLAUSE_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_SHOW_CAROUSEL_PLAY_AND_PLAUSE == null) {
  GATSBY_SHOW_CAROUSEL_PLAY_AND_PLAUSE_INIT = "false";
  // eslint-disable-next-line no-undef
} else {
  GATSBY_SHOW_CAROUSEL_PLAY_AND_PLAUSE_INIT =
    // eslint-disable-next-line no-undef
    process.env.GATSBY_SHOW_CAROUSEL_PLAY_AND_PLAUSE;
}
export const GATSBY_SHOW_CAROUSEL_PLAY_AND_PLAUSE = GATSBY_SHOW_CAROUSEL_PLAY_AND_PLAUSE_INIT;

let GATSBY_TACO_TITLE_COLOR_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_TACO_TITLE_COLOR == null) {
  GATSBY_TACO_TITLE_COLOR_INIT = "primary";
  // eslint-disable-next-line no-undef
} else {
  GATSBY_TACO_TITLE_COLOR_INIT =
    // eslint-disable-next-line no-undef
    process.env.GATSBY_TACO_TITLE_COLOR;
}
export const GATSBY_TACO_TITLE_COLOR = GATSBY_TACO_TITLE_COLOR_INIT;

let GATSBY_HEADER_MENU_SPACING_INIT;
// eslint-disable-next-line no-undef
if (process.env.GATSBY_HEADER_MENU_SPACING == null) {
  GATSBY_HEADER_MENU_SPACING_INIT = "false";
  // eslint-disable-next-line no-undef
} else {
  GATSBY_HEADER_MENU_SPACING_INIT =
    // eslint-disable-next-line no-undef
    process.env.GATSBY_HEADER_MENU_SPACING;
}
export const GATSBY_HEADER_MENU_SPACING = GATSBY_HEADER_MENU_SPACING_INIT;
