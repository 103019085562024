import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";

const CookieButton = (): JSX.Element => {
  const classNames = classnames(styles["container"]);
  return (
    <div className={classNames}>
      <a id="ot-sdk-btn" className="ot-sdk-show-settings active">
        Cookie Settings
      </a>
    </div>
  );
};

export default CookieButton;
