import React, { useState } from "react";
import SiteHeader from "../components/Header";
import SiteFooter from "../components/Footer";
import Container from "../components/Container";
import styles from "./layout.module.scss";

interface PropTypes {
  children?: React.ReactNode;
  siteFooterData?: any;
}

const DefaultLayout = ({
  children,
  siteFooterData,
}: PropTypes): JSX.Element => {
  const [activeness, setActiveness] = useState(false);
  const toggleMenu = () => {
    setActiveness(!activeness);
  };
  return (
    <div>
      <SiteHeader
        activeness={activeness}
        handleView={toggleMenu}
        footerData={siteFooterData}
      />
      <Container activeness={activeness} bgColor="white">
        <div className={styles["flexContainer"]}>{children}</div>
      </Container>
      <SiteFooter activeness={activeness} siteFooterData={siteFooterData} />
    </div>
  );
};

export default DefaultLayout;
