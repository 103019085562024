import React from "react";
import { graphql, StaticQuery } from "gatsby";
import axios from "axios";
import "@cncxt/imodium-component-library/build/components/molecules/Navigation/rc-menu-styles.scss";
import {
  Logo,
  Button,
  Navigation,
  Anchor,
  Icon,
  Header,
  TextField,
  Sidebar,
} from "@cncxt/imodium-component-library";
//import "rc-menu/assets/index.css";
import Search from "../Search";
import styles from "./header.module.scss";
import { randomNumber } from "../../utils/miscFunctions";
import { mainMenuInterface } from "../../utils/interfaces/layoutInterfaces";
import { useTranslation } from "react-i18next";
import { withTrans } from "../../i18n/withTrans";
import {
  GATSBY_SITE_MULTILINGUAL,
  GATSBY_SITE_DIRECTION,
  GATSBY_SHOW_WHERE_TO_BUY,
  GATSBY_LANG_SWITCH_LINK,
  GATSBY_SHOW_WHERE_TO_BUY_LINK,
  GATSBY_SHOW_DESKTOP_MENU_HOME_ICON,
  GATSBY_SHOW_MOBILE_MENU_HOME_ICON,
  GATSBY_HEADER_MENU_SPACING,
} from "../../utils/constants";

/*
  Following style sheet from the library used for the navigation component
  Need to import in the gatsby application again to apply the styles related to that library
  This library will get installed from the @cncxt/imodium-component-library package as dependancy
*/

interface Props {
  handleView?: () => void;
  activeness?: boolean;
  footerData: string;
}

const SiteHeader = (Props): JSX.Element => {
  const { t } = useTranslation();
  const { handleView, activeness, footerData } = Props;

  return (
    <StaticQuery
      query={graphql`
        query MainMenuQuery {
          imodiumMainMenu {
            data {
              ...MainMenuFragment
            }
          }
        }
      `}
      render={({ imodiumMainMenu }: mainMenuInterface) => {
        const headerNavs = imodiumMainMenu.data;
        const switchButton = () => {
          if (GATSBY_SITE_MULTILINGUAL == "false") {
            return "";
          } else {
            return (
              <Anchor
                color={"headerLang"}
                label={t("header.switch_lang_text", {
                  defaultValue: "ENGLISH",
                })}
                link={GATSBY_LANG_SWITCH_LINK}
              ></Anchor>
            );
          }
        };

        const whereToBuy = () => {
          if (GATSBY_SHOW_WHERE_TO_BUY == "true") {
            return (
              <Anchor
                link={GATSBY_SHOW_WHERE_TO_BUY_LINK}
                label={t("header.where_to_buy", {
                  defaultValue: "Where to Buy",
                })}
                color="white"
              />
            );
          } else {
            return "";
          }
        };

        const whereToBuyMobile = () => {
          if (GATSBY_SHOW_WHERE_TO_BUY == "true") {
            return (
              <Anchor
                color="primaryTransparent"
                link={GATSBY_SHOW_WHERE_TO_BUY_LINK}
                label={t("header.where_to_buy", {
                  defaultValue: "Where to Buy",
                })}
              ></Anchor>
            );
          } else {
            return "";
          }
        };

        return (
          <div className={styles["container"]}>
            <Header
              direction={GATSBY_SITE_DIRECTION}
              menuspacing={GATSBY_HEADER_MENU_SPACING == "true"}
              handleView={handleView}
              active={activeness}
              anchor={whereToBuy()}
              textfield={
                <Search
                  name="textfield"
                  classn="headerInput"
                  placeholder={t("header.search_text", {
                    defaultValue: "search...",
                  })}
                  onChange={async event => {
                    const domainURL = process.env.GATSBY_CMS_API_URL;
                    const endpoint = "/search-predictions/";
                    const data = await axios.get(
                      `${domainURL}${endpoint}${event.target.value}`,
                    );
                    return data.data.data;
                  }}
                  endAdornment={<Icon icon="magnify" size="headerSearch" />}
                  footerData={footerData}
                />
              }
              mobileSearchIcon={<Icon icon="magnify" size="extraLarge" />}
              sideBarMenuIcon={<Icon icon="menu" size="extraLarge" />}
              sideBarMenuCrossIcon={
                <svg width="44px" height="44px" viewBox="0 0 44 44">
                  <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g id="Group">
                      <g fill="#006699" id="Rectangle">
                        <rect x="0" y="0" width="44" height="44"></rect>
                      </g>
                      <g
                        transform="translate(8.000000, 8.000000)"
                        fill="#FFFFFF"
                        id="Group"
                      >
                        <rect
                          id="Rectangle"
                          transform="translate(14.500000, 14.500000) rotate(45.000000) translate(-14.500000, -14.500000) "
                          x="-2"
                          y="11"
                          width="33"
                          height="7"
                        ></rect>
                        <rect
                          id="Rectangle-Copy"
                          transform="translate(14.500000, 14.500000) scale(1, -1) rotate(45.000000) translate(-14.500000, -14.500000) "
                          x="-2"
                          y="11"
                          width="33"
                          height="7"
                        ></rect>
                      </g>
                    </g>
                  </g>
                </svg>
              }
              lang={switchButton()}
              logo={
                <Anchor color="primaryTransparent" link="/">
                  <Logo source="https://www.imodium.com.au/sites/imodium_au/files/imodium-logo-new.png" />
                </Anchor>
              }
              list={
                <Navigation>
                  {headerNavs.map((menu, i) =>
                    i != 0 || GATSBY_SITE_DIRECTION != "ltr" ? (
                      menu.submenu.length > 0 ? (
                        menu.submenu.filter(
                          sub => sub.submenu && sub.submenu.length > 0,
                        ).length > 0 ? (
                          <Navigation.MegaMenu
                            key={`mega - menu${i + randomNumber} `}
                            link={menu.link}
                            title={menu.title}
                            submenu={menu.submenu}
                          ></Navigation.MegaMenu>
                        ) : (
                          <Navigation.SubMenu
                            key={`sub - menu${i + randomNumber} `}
                            link={menu.link.href}
                            title={menu.title}
                            submenu={menu.submenu}
                          ></Navigation.SubMenu>
                        )
                      ) : (
                        <Navigation.SubMenu
                          key={`sub - menu${i + randomNumber} `}
                          link={menu.link.href}
                          title={menu.title}
                          submenu={menu.submenu}
                        ></Navigation.SubMenu>
                      )
                    ) : (
                      <Navigation.MenuItem
                        key={`main - menu${i + randomNumber} `}
                      >
                        <Anchor
                          color="primaryTransparent"
                          link={menu.link.href}
                        >
                          {GATSBY_SHOW_DESKTOP_MENU_HOME_ICON == "true" && (
                            <svg
                              style={{ height: "25px" }}
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="home"
                              className="svg-inline--fa fa-home fa-w-18"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M280.37 148.26L96 300.11V464a16 16 0 0 0
                          16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47
                          43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                              ></path>
                            </svg>
                          )}
                          {GATSBY_SHOW_DESKTOP_MENU_HOME_ICON == "false" &&
                            menu.title}
                        </Anchor>
                      </Navigation.MenuItem>
                    ),
                  )}
                </Navigation>
              }
              sideBarMenu={
                <Sidebar
                  textfield={whereToBuyMobile()}
                  listMenu={
                    <Navigation mode="inline">
                      {headerNavs.map((menu, i) =>
                        menu.submenu.length > 0 ? (
                          menu.submenu.filter(
                            sub => sub.submenu && sub.submenu.length > 0,
                          ).length > 0 ? (
                            <Navigation.MegaMenu
                              key={`sidebar - mega - menu${i + randomNumber} `}
                              link={menu.link}
                              title={menu.title}
                              submenu={menu.submenu}
                              mode="inline"
                            ></Navigation.MegaMenu>
                          ) : (
                            <Navigation.SubMenu
                              key={`sidebar - sub - menu${i + randomNumber} `}
                              link={menu.link.href}
                              title={menu.title}
                              submenu={menu.submenu}
                              mode="inline"
                            ></Navigation.SubMenu>
                          )
                        ) : (
                          <Navigation.MenuItem
                            key={`sidebar - main - menu${i + randomNumber} `}
                          >
                            <Anchor
                              color="primaryTransparent"
                              link={menu.link.href}
                            >
                              {GATSBY_SHOW_MOBILE_MENU_HOME_ICON == "true" &&
                                i == 0 && (
                                  <svg
                                    style={{ height: "25px" }}
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="home"
                                    className="svg-inline--fa fa-home fa-w-18"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M280.37 148.26L96 300.11V464a16 16 0 0 0
                                16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47
                                43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                                    ></path>
                                  </svg>
                                )}
                              {GATSBY_SHOW_MOBILE_MENU_HOME_ICON == "false" &&
                                menu.title}
                              {GATSBY_SHOW_MOBILE_MENU_HOME_ICON == "true" &&
                                i != 0 &&
                                menu.title}
                            </Anchor>
                          </Navigation.MenuItem>
                        ),
                      )}
                    </Navigation>
                  }
                />
              }
            />
          </div>
        );
      }}
    />
  );
};
export default withTrans(SiteHeader);
