import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";

interface PropTypes {
  children?: React.ReactNode;
  bgColor?: "white";
  [x: string]: any;
  activeness?: boolean;
}

const Container = ({
  children,
  bgColor,
  activeness,
  ...props
}: PropTypes): JSX.Element => {
  const classNames = classnames(
    styles["container"],
    bgColor === "white" ? styles["bgColor-white"] : null,
    activeness ? styles["container-slide"] : styles["container-full"],
  );
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};

export default Container;
