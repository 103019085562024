import i18next from "i18next";
import { GATSBY_SITE_TRANSLATION } from "../utils/constants";
// eslint-disable-next-line no-undef
let value = GATSBY_SITE_TRANSLATION;
i18next.init({
  fallbackLng: value.replace("-", "_"),
  resources: {
    au_en: {
      translations: require("../locales/au_en.translations.json"),
    },
    ar_ar: {
      translations: require("../locales/ar_ar.translations.json"),
    },
    ar_en: {
      translations: require("../locales/ar_en.translations.json"),
    },
    hu_hu: {
      translations: require("../locales/hu_hu.translations.json"),
    },
    default: {
      translations: require("../locales/default.translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  // eslint-disable-next-line no-undef
  debug: process.env.GATSBY_ACTIVE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
});

i18next.languages = ["ar-DZ", "en-au", "ar_ar"];

export default i18next;
